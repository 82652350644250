import { makeObservable, observable } from 'mobx'

export function makeObservableDef(target, decorators) {
  for (const key in decorators) {
    if (!target.hasOwnProperty(key)) {
      const d = decorators[key]
      if (d === observable || d === observable.ref) {
        target[key] = undefined
      }
    }
  }
  makeObservable(target, decorators)
}
